/* You can add global styles to this file, and also import other style files */
@import "assets/scss/shared/app.fonts.scss";
@import "assets/scss/shared/app.scss";

@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
         transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
@keyframes opacityAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes colorchange
    {
      0%   {background: rgba(236, 220, 220, 0.25); }
      20%  {background: rgba(230, 233, 209, 0.25); }
      40%  {background: rgba(226, 238, 220, 0.25); }
      60% {background: rgba(235, 220, 232, 0.25); }
      80% {background: rgba(219, 226, 238, 0.25); }
      100% {background: rgba(236, 220, 220, 0.25); }
   }
.loading-spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    transition: opacity 0.5s ease, height 0.01s linear 0.6s, width 0.01s linear 2.6s;
    background: rgba(150,156,173,0.26);
    background: radial-gradient(rgba(150,156,173,0.26), rgba(205,206,210,0.28));
    
    opacity: 0;
    z-index: 10000000;
    &.full-screen {
        position: fixed;
        // background: black;
    }
    
    .loading-spinner {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url(/assets/images/circleA.svg);
        transform: translate3d(-50%, -50%, 0);
        transform-origin: 45% 53.5%;
        background-size: contain;
        top: 50%;
        left: 50%;
        background-repeat: no-repeat;
        animation: 2s cubic-bezier(0.65, 0.21, 0.44, 0.82) infinite spinner;
        animation: 2s linear infinite spinner;
        display: none;
    }
    &.active {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity 1s linear;
        .loading-spinner {
            display: block;
        }
    }
}
.spinner-container {
    width: 100vw;
    height: 100vh;
    // background-color: gray;
    // transition: all .5s ease;
    position: fixed;
    // animation: colorchange 20s linear infinite;
    &.black {
        background: black;
        z-index: 200000;
        animation: opacityAnimation 1s ease;
    }
    .loading-spinner {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url(/assets/images/circleA.svg);
        transform: translate3d(-50%, -50%, 0);
        transform-origin: 45% 53.5%;
        background-size: contain;
        top: 50%;
        left: 50%;
        background-repeat: no-repeat;
        animation: 2s cubic-bezier(0.65, 0.21, 0.44, 0.82) infinite spinner;
        animation: 2s linear infinite spinner;
        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     left: 0;
        //     top: 50%;
        //     height: 1px;
        //     background: red;
        // }
        // &:before {
        //     content: "";
        //     position: absolute;
        //     width: 1px;
        //     left: 50%;
        //     top: 0;
        //     height: 100%;
        //     background: red;
        // }
    }
}